import { ScrollTrigger } from "gsap/all";

export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.header = document.querySelector('.header');
    this.footer = document.querySelector('.footer');
    this.badge = this.header.querySelector('.badge');
    this.badgeInner = this.header.querySelector('.badge-inner');
    this.threshold = 5;
    this.locked = false;

    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });
    this.scrollHandler();

    let oldLeft = false;
    ScrollTrigger.create({
      onUpdate: ({ progress }) => {
        let left = progress > 0.4;
        if (oldLeft != left) {
          this.badge.classList.add('!scale-0', '!duration-200');
          setTimeout(() => {
            this.badge.classList.toggle('badge-right', !left);
            this.badge.classList.toggle('badge-left', left);
            this.badge.classList.remove('!scale-0', '!duration-200');
          }, 700);
          oldLeft = left;
        }
      },
    })

    window.addEventListener('resize', (event) => {
      this.resizeHandler();
    });
    this.resizeHandler();
  }

  scrollHandler() {
    const hasScrolled = this.header.classList.contains('has-scrolled');
    this.badgeInner.classList.toggle('text-theme-green', (this.body.clientHeight - window.innerHeight) / 2.5 < window.scrollY);
    this.badgeInner.classList.toggle('text-theme-purple', (this.body.clientHeight - window.innerHeight) / 2.5 > window.scrollY);

    if (!this.locked) {
      const changedHeader = this.header.classList.toggle('has-scrolled', this.html.scrollTop > this.threshold);
      if (hasScrolled != changedHeader) {
        this.locked = true;
        setTimeout(() => {
          this.resizeHandler();
          this.locked = false;
        }, 700);
      }
    }
  }

  resizeHandler() {
    this.html.style.setProperty('--header-height', this.header.clientHeight + 'px');
    this.html.style.setProperty('--footer-height', this.footer.clientHeight + 'px');

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 750);
  }

}
