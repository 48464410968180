class ProjectTeaser extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener('mouseenter', (event) => {
      this.classList.add('hover');
    });
    this.addEventListener('mouseleave', (event) => {
      this.classList.remove('hover');
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('project-teaser', ProjectTeaser);
}
