import barba from '@barba/core';
var Chance = require('chance');
import { gsap, ScrollTrigger } from "gsap/all";
import Cookies from 'js-cookie';
import Lenis from 'lenis'
import 'lenis/dist/lenis.css'

import Header from "./header";
// import NavMobile from "./nav-mobile";
import Reveal from "./reveal";

export default class BarbaJs {

  constructor() {
    window.scrollTo(0, 0);

    const chance = new Chance();
    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    lenis.on('scroll', ScrollTrigger.update);

    const body = document.querySelector('body');
    // const badge = document.querySelector('.badge');

    // const getRandomInt = (min, max) => {
    //   min = Math.ceil(min);
    //   max = Math.floor(max);
    //   return Math.floor(Math.random() * (max - min + 1)) + min;
    // }

    const getAnalyticsContent = function () {
      let analytics_consent = false;
      const cookie_status = Cookies.get('cookie_status');
      if (cookie_status) {
        const categories = cookie_status.split(',');
        analytics_consent = categories.includes('analytics');
      }
      return analytics_consent;
    }


    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    // Set default consent values
    gtag('consent', 'default', {
      'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
      'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
      'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
    });

    // listen for cookie updates
    body.addEventListener('cookies:saved', (event) => {
      gtag('consent', 'update', {
        'ad_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_user_data': event.detail.includes('analytics') ? 'granted' : 'denied',
        'ad_personalization': event.detail.includes('analytics') ? 'granted' : 'denied',
        'analytics_storage': event.detail.includes('analytics') ? 'granted' : 'denied',
      });

      // history pushState to trigger GTM Tag
      const url = new URL(window.location);
      history.pushState({}, "", url);
    });


    barba.init({
      timeout: 5000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new Reveal('.js--reveal');
            }, 1);


            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },


          beforeLeave(data) {
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.3,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },


          afterLeave(data) {
            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');

            data.current.container.remove();
          },


          beforeEnter(data) {
            // Set default consent values
            gtag('consent', 'default', {
              'ad_storage': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_user_data': getAnalyticsContent() ? 'granted' : 'denied',
              'ad_personalization': getAnalyticsContent() ? 'granted' : 'denied',
              'analytics_storage': getAnalyticsContent() ? 'granted' : 'denied',
            });

            data.next.container.style.opacity = 0;
          },


          enter(data) {
            // reinit JS
            setTimeout(() => {
              new Reveal('.js--reveal');
            }, 1);

          },


          afterEnter(data) {
            // update heart animation values
            const animatedHearts = document.querySelectorAll('.animated-heart');
            animatedHearts.forEach((heart, index) => {
              let x = index % 2 === 0 ? chance.integer({ min: -300, max: -100 }) : chance.integer({ min: 75, max: 300 });
              heart.style.setProperty('--offset-x', x + '%');
              heart.style.setProperty('--offset-y', chance.floating({ min: 0.5, max: 0.55 }));
            });

            // // change badge color
            // badge.classList.remove('text-theme-purple');
            // badge.classList.remove('text-theme-green');
            // badge.classList.add(chance.bool() ? 'text-theme-green' : 'text-theme-purple');

            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 0.3,
              delay: 0.2,
              onStart: () =>  {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              },
            });
          }

        },
      ],

    });
  }

}
